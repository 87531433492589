import {Link, useLocation, withRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState, useRef} from "react";
import {getAffReport, getSharingReport} from "../../reducers/tools/Report";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {initConfigInputLastMonth, initConfigInputTime} from "../../services/config";
import CurrencyFormat from "react-currency-format";
import Pagination from "react-js-pagination";
import {rowRuning} from "../../services/functions";


const Sharing = () => {

    const datetimePikerRef = useRef();

    let dataStatePOP = useLocation();

    const dispatch = useDispatch();
    const [selectedStartDate, setSelectedStartDate] = useState(new Date(new Date(moment().subtract(30, 'day').format()).setHours(0, 0, 0, 0)));
    const [selectedEndDate, setSelectedEndDate] = useState(moment(new Date(new Date().setHours(23, 50, 59, 0))).format());
    const [activePage, setActivePage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(15);
    const [click, setClick] = useState(false)

    const sharingList = useSelector((state) => {
        return state.report.sharingReport || [];
    });

    useEffect(() => {
        const start = moment(selectedStartDate).format();
        const end = moment(selectedEndDate).format();
        dispatch(getSharingReport(start, end, itemPerPage));

        dispatch(getAffReport())

    }, []);



    function handleCallBackDateRang(start, end, label) {
        setSelectedStartDate(moment(start).format());
        setSelectedEndDate(moment(end).format());
    }

    const handlePageSizeChange = (pageSize) => {
        setItemPerPage(pageSize)
        const start = moment(selectedStartDate).format();
        const end = moment(selectedEndDate).format();
        dispatch(getSharingReport(start, end, pageSize));
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        const start = moment(selectedStartDate).format();
        const end = moment(selectedEndDate).format();
        dispatch(getSharingReport(start, end, itemPerPage));
    }

    useEffect(() => {

        // console.log(dataStatePOP.state)
        if (typeof dataStatePOP.state != 'undefined') {
            const begin = typeof dataStatePOP.state != 'undefined' ? moment(new Date(dataStatePOP.state.startDate)) : moment();
            const end = typeof dataStatePOP.state != 'undefined' ? moment(new Date(dataStatePOP.state.endDate)) : moment();
            setSelectedStartDate(moment(new Date(begin)).format());
            setSelectedEndDate(moment(new Date(end)).format());
            datetimePikerRef.current.setStartDate(begin);
            datetimePikerRef.current.setEndDate(end);
            const _start = moment(selectedStartDate).format();
            const _end = moment(selectedEndDate).format();
            // console.log('xxxxx')
            dispatch(getSharingReport(_start, _end, itemPerPage));
        } else {
            //dispatch(getSharingReport(selectedStartDate, selectedEndDate, itemPerPage))
        }

    }, [dataStatePOP, dispatch, itemPerPage, selectedEndDate, selectedStartDate])


    function onSubmit() {
        const start = moment(selectedStartDate).format();
        const end = moment(selectedEndDate).format();
        dispatch(getSharingReport(start, end, itemPerPage));
    }

    return (
        <>
            <div className="page-header page-header-light">
                <div className="page-header-content header-elements-md-inline align-items-center">
                    <div className="page-title d-flex">
                        <h4>รายงานส่วนแบ่ง</h4>
                    </div>
                </div>
                <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                    <div className="d-flex">
                        <div className="breadcrumb">
                            <Link to="/" className="breadcrumb-item">
                                <i className="icon-home2 mr-2"></i> หน้าแรก
                            </Link>
                            <span className="breadcrumb-item active">รายงานส่วนแบ่ง</span>
                        </div>
                        <a
                            href="/#"
                            className="header-elements-toggle text-default d-md-none"
                        >
                            <i className="icon-more"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="card mb-4">
                    <div className="card-body">
                        <div className="form-inline">
                            <div className="form-group mr-2">
                                <label className="pr-1">รายการต่อหน้า</label>
                                <select className="form-control form-control-sm" onChange={(e) => handlePageSizeChange(e.target.value)}>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <div className="form-group mr-2">
                                <DateRangePicker
                                    ref={datetimePikerRef}
                                    initialSettings={initConfigInputLastMonth}
                                    onCallback={handleCallBackDateRang}
                                >
                                    <input type="text" style={{width: "164px"}} className="form-control form-control-sm"/>
                                </DateRangePicker>
                            </div>
                            <div className="form-group mr-2">
                                <button onClick={() => onSubmit()} className="btn btn-primary btn-sm">ค้นหา</button>
                            </div>
                        </div>
                    </div>

                    <div className="table-responsive">
                        <table className="table table-sm table-striped table-bordered">
                            <thead>
                            {sharingList.data && sharingList.data.length > 0 && (
                                <tr>
                                    <td colSpan={4} className="text-right font-weight-bold border-bottom-0">รวม</td>
                                    <td className={"text-right font-weight-bold h3 border-bottom-0 " + (sharingList.sum.total > 0 ? 'text-success' : 'text-danger')}>
                                        <CurrencyFormat value={sharingList.sum.total} displayType={'text'} thousandSeparator={true}/>
                                    </td>
                                    <td className={"text-right font-weight-bold h3 border-bottom-0 " + (sharingList.sum.company > 0 ? 'text-success' : 'text-danger')}>
                                        <CurrencyFormat value={sharingList.sum.company} displayType={'text'} thousandSeparator={true}/>
                                    </td>
                                    <td className={"text-right font-weight-bold h3 border-bottom-0 " + (sharingList.sum.upline > 0 ? 'text-success' : 'text-danger')}>
                                        <CurrencyFormat value={sharingList.sum.upline} displayType={'text'} thousandSeparator={true}/>
                                    </td>

                                </tr>
                            )}
                            <tr>
                                <th className="text-center" width={60}>ลำดับ</th>
                                <th>ID</th>
                                <th className="text-right" width={150}>Bet</th>
                                <th className="text-right" width={150}>Win</th>
                                <th className="text-right" width={150}>Total</th>
                                <th className="text-right" width={150}>Company</th>
                                <th className="text-right" width={150}>Upline</th>

                            </tr>
                            </thead>
                            <tbody>

                            {sharingList.data && sharingList.data.length > 0 ? sharingList.data.map((item, i) => (
                                <tr key={item.username}>
                                    <td className="text-center">{rowRuning(i, activePage, sharingList?.pageSize)}</td>
                                    <td>
                                        <Link class="text-info font-weight-bold" to={`/report/gamehistory?username=${item.username}`}>{item.username}</Link>
                                    </td>
                                    <td className="text-right">
                                        <CurrencyFormat value={item.bet} displayType={'text'} thousandSeparator={true}/>
                                    </td>
                                    <td className="text-right">
                                        <CurrencyFormat value={item.win} displayType={'text'} thousandSeparator={true}/>
                                    </td>
                                    <td className="text-right">
                                        <CurrencyFormat value={item.total} displayType={'text'} thousandSeparator={true}/>
                                    </td>
                                    <td className="text-right">
                                        <CurrencyFormat value={item.company} displayType={'text'} thousandSeparator={true}/>
                                    </td>
                                    <td className="text-right">
                                        <CurrencyFormat value={item.upline} displayType={'text'} thousandSeparator={true}/>
                                    </td>

                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan={6} className="text-center">ไม่พบข้อมูล</td>
                                </tr>
                            )}

                            </tbody>
                        </table>
                    </div>


                </div>

                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={sharingList?.pageSize}
                        totalItemsCount={sharingList?.count ? sharingList?.count : 0}
                        pageRangeDisplayed={5}
                        onChange={e => handlePageChange(e)}
                    />

            </div>
        </>
    )
}


export default withRouter(Sharing)
