import * as http from "../http-common";

export function GetGameHistory(username, _page, _pageSize, _from, _to) {
    const page = _page >= 1 ? _page : 1;
    const pageSize = _pageSize >= 15 ? `&pageSize=${_pageSize}` : `&pageSize=15`;
    const from = _from ? `&startTime=${_from}` : ``;
    const to = _to ? `&endTime=${_to}` : ``;

    return http.get(`report/gamehistory/${username}?page=${page}${pageSize}${from}${to}`);
}


export function GetSharingReport(from, to, itemPerPage){
    // from = from.replace("+", " ")
    // to = to.replace("+", " ")
    return http.get(`report/sharing?startTime=${from}&endTime=${to}&pageSize=${itemPerPage}`)
}

export function GetUsersReport(from, to, itemPerPage, page = 1){
    return http.get(`user/byregister?date=${from}&endTime=${to}&pageSize=${itemPerPage}&page=${page}`)
}

export function GetUserById(id) {
    return http.get(`user/${id}`)
}


export function GetBonusReport(page, pageSize, from, to, username){
    return http.get(`transaction/bonus?startTime=${encodeURIComponent(from)}&endTime=${encodeURIComponent(to)}&pageSize=${pageSize}&page=${page}&name=${username}`)
}


export function GetAffReport() {
    return http.get(`aff/affincome`)
}
